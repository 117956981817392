<template lang="pug">
  .panel-page
    .panel-page__head
      h1.panel-page__title {{ title }}
    .panel-page__body(v-loading="loading")
      template(v-if="!loading")
        profile(:data="data.widgets.profile")
        actions(:data="data.widgets.actions")

    back-to-top
</template>
<script>
  import { notifyErrorRequest } from '@/utils/api'
  import SaveRoutes from '@/views/Templates/SaveRoutes'
  import BackToTop from '@/components/BackToTop'
  import Profile from './Profile'
  import Actions from './Actions'

  export default {
    name: 'PanelPage',
    extends: [SaveRoutes],
    components: { BackToTop, Profile, Actions },
    data() {
      return {
        title: 'Информация',
        loading: true,
        data: null
      }
    },
    async created() {
      const fetchedData = await this.fetchData()
      this.data = fetchedData
      this.loading = false
    },
    computed: {
      request() {
        return this.$route.meta.request
      },
    },
    methods: {
      async fetchData() {
        const response = await notifyErrorRequest({
          url: `${this.request}`,
          method: 'get',
          params: {},
        })
        return response
      },
    }
  }
</script>
<style lang="sass">
  .panel-page
    min-height: calc(100vh - 84px)
    padding: 32px
    background-color: #f0f2f5
    &__title
      margin-top: 0
      margin-bottom: 30px
      color: rgba(0, 0, 0, 0.55)
</style>
