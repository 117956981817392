<template lang="pug">
  .panel-actions
    h2.panel-actions__title Последние события
    el-table(
      :data="data.items"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    ).panel-actions__table
      el-table-column(label="Раздел" width="120")
        template(slot-scope="{ row }") {{ row.domain }}
      el-table-column(label="Действие" min-width="30")
        template(slot-scope="{ row }") {{ row.action }}
      el-table-column(label="Страница" min-width="30")
        template(slot-scope="{ row }") {{ row.page }}
      el-table-column(label="Результат" min-width="40")
        template(slot-scope="{ row }") {{ row.result }}
      el-table-column(label="Дата" width="180")
        template(slot-scope="{ row }") {{ row.created_at }}
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => ({}) }
    },
    methods: {
      logScope(scope) {
        console.log(scope)
      },
      tableRowClassName({ row, rowIndex }) {
        return 'panel-actions__row_' + row.status
      }
    }
  }
</script>
<style lang="sass">
  .panel-actions
    background: rgb(255, 255, 255)
    padding: 16px 20px
    margin-bottom: 32px
    &__title
      line-height: 24px
      color: rgba(0, 0, 0, 0.55)
      font-size: 20px
      margin-top: 0
      margin-bottom: 12px
    &__table
      font-size: 14px
      line-height: 16px

  tr.panel-actions__row
    &_success
      background: #f0f9eb
    &_warning
      background: oldlace
    &_danger
      background: #ffb6b0

</style>
