<template lang="pug">
  .panel-profile
    h2.panel-profile__title Профиль
    .panel-profile__row
      .panel-profile__name {{ data.name }}
      .panel-profile__sub-row
        .panel-profile__col
          .panel-profile__item
            .panel-profile__item-name E-mail:
            .panel-profile__item-value {{ data.email }}
          .panel-profile__item
            .panel-profile__item-name Роль:
            .panel-profile__item-value {{ data.role }}
        .panel-profile__col
          .panel-profile__item
            .panel-profile__item-name Пароль&nbsp;закончится:
            .panel-profile__item-value(v-html="data.password_expiration_date")
          .panel-profile__item
            .panel-profile__item-name Дополнительных&nbsp;разрешений:
            .panel-profile__item-value {{ data.permissions_count }}
        .panel-profile__col
          router-link(to="/admin/password-change")
            el-button(type="primary" style="background: #036287; border-color: #036287").panel-profile__btn Изменить пароль
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => ({}) }
    },
    methods: {
    }
  }
</script>
<style lang="sass">
  .panel-profile
    display: flex
    flex-flow: row nowrap
    background: rgb(255, 255, 255)
    padding: 16px 20px
    margin-bottom: 32px
    align-items: center
    &__title
      line-height: 24px
      color: rgba(0, 0, 0, 0.55)
      font-size: 20px
      margin: 0
      padding-right: 35px
    &__row
      display: flex
      flex-flow: row wrap
      align-items: center
      justify-content: space-between
      width: 100%
    &__sub-row
      display: flex
      flex-flow: row wrap
      align-items: center
    &__col
      &:not(:last-child)
        padding-right: 35px
    &__name
      font-size: 24px
      line-height: 30px
      font-weight: 400
      color: #036287
      padding-right: 35px
    &__item
      display: flex
      flex-flow: row nowrap
      color: #036287
    &__item-name
      font-size: 12px
      line-height: 20px
      font-weight: 700
      padding-right: 10px
    &__item-value
      font-size: 14px
      line-height: 20px
      font-weight: 400
</style>
